import React, { Component } from "react"
import { Link } from "gatsby"

export class Footer extends Component {
  render() {
    return (
      <>
        <div className="advertisement-container">
          <div className="advertisement">
            <img
              src="https://njtautomation.com/static/faceiconBlack-49771a8eb6dbd7b52a39773547e75170.png"
              alt="Sell Allen Bradley Industrial Components"
              className="advertisementImage2"
            />
            <h2>
              Buy & Sell Surplus Industrial Components with{" "}
              <a
                href="https://njtautomation.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                NJT Automation
              </a>
            </h2>
            <p>
              Looking to sell your surplus Allen Bradley industrial components?
              NJT Automation offers competitive prices and a hassle-free selling
              process. Contact us today to get started!
            </p>
            <a
              href="https://njtautomation.com/Sell-Components/"
              target="_blank"
              rel="noopener noreferrer"
              className="advertisementLink"
            >
              Sell Your Components
            </a>
          </div>

          <div className="advertisement">
            <img
              src="https://njtautomation.com/static/recycle-67b3502565922c6764b4ed5b6f978570.png"
              alt="Electronics Recycling Milwaukee"
              className="advertisementImage2"
            />
            <h2>Milwaukee Electronics Recycling</h2>
            <p>
              Responsible electronics recycling is crucial for a sustainable
              future. NJT Automation provides comprehensive electronics
              recycling services in Milwaukee and surrounding areas. Recycle
              your old electronics with us and contribute to a greener planet.
            </p>
            <a
              href="https://njtautomation.com/milwaukee/"
              target="_blank"
              rel="noopener noreferrer"
              className="advertisementLink"
            >
              Learn More
            </a>
          </div>
        </div>

        <div className="footer">
          <Link to="/" className="footerLink">
            Home
          </Link>
          <Link to="/about" className="footerLink">
            About
          </Link>
          <Link to="/contact" className="footerLink">
            Contact
          </Link>
          <Link to="/faq" className="footerLink">
            FAQ
          </Link>
        </div>
      </>
    )
  }
}

export default Footer
