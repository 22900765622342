import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useEffect } from "react"
import Advertisements from "../components/advertisements"
import { Link } from "gatsby"

import Header from "./header"
import CountryBar from "./countrybar"
import Footer from "./footer"

const LoadScript = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://epnt.ebay.com/static/epn-smart-tools.js"
    script.async = true
    document.getElementById("script").appendChild(script)
  }, [])

  return (
    <>
      <div id="script" />
      <div className="ebayContainer">
        <div className="ebay">
          <ins
            className="epn-placement"
            data-config-id="5efc984c5da29225ccf11fd5"
          ></ins>
        </div>
      </div>
    </>
  )
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: "100vw",
            position: "relative",
          }}
        >
          <Header siteTitle={data.site.siteMetadata.title} />{" "}
          <main>{children}</main>
          <div>
            <LoadScript />
          </div>
        </div>

        <div className="headings">
          <h1> Search facebook marketplace over 100 miles</h1>
          <h2>
            {" "}
            See what is for sale in the entire world on facebook marketplace{" "}
          </h2>
        </div>
        <CountryBar />

        <Advertisements />
        <div className="notWorking">
          <Link to="/faq"> Website not working? Click here </Link>
          <p></p>
          <p></p>
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
