import React, { Component } from "react"

export class advertisements extends Component {
  render() {
    return (
      <div>
        <div className="advertisements"></div>
      </div>
    )
  }
}

export default advertisements
